import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import styled from "styled-components"
import reviews from "../static/utils/customerReviews.json"
import { successColor, HEROBlack, HEROPrimary } from "../static/styles/colors"
import { IoIosArrowForward } from "@react-icons/all-files/io/IoIosArrowForward"
import { IoIosArrowBack } from "@react-icons/all-files/io/IoIosArrowBack"
import { ImQuotesLeft } from "@react-icons/all-files/im/ImQuotesLeft"
import { ImQuotesRight } from "@react-icons/all-files/im/ImQuotesRight"
import { IoStarSharp } from "@react-icons/all-files/io5/IoStarSharp"
import TextCompactor from "./common/textCompactor"
import "slick-carousel/slick/slick.css"

const ReviewContainer = ({ isHERO }) => {
  const [sliderCardHeight, setSliderCardHeight] = useState("260px")
  const headlineStyle = isHERO
    ? "text-center text-light fs-InterBold"
    : "text-uppercase text-center text-light fw-bold"
  const headlineHighlightStyle = isHERO ? "text-HERO-primary" : "text-success"
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
    nextArrow: <NextArrow handleColor={isHERO} />,
    prevArrow: <PrevArrow handleColor={isHERO} />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  useEffect(() => {
    window.innerWidth >= 576
      ? setSliderCardHeight("260px")
      : setSliderCardHeight("300px")
  }, [])

  return (
    <div className="container">
      <Separator />
      <div className="row">
        <div className="col-12">
          <h2 className={headlineStyle}>
            Was unsere Kunden über uns{" "}
            <span className={headlineHighlightStyle}>sagen</span>
          </h2>
        </div>
        <div className="col-11 mx-auto mt-5">
          <Slider {...settings}>
            {reviews.map(review => (
              <div key={review.id}>
                <div
                  className="row rounded p-3 m-4 bg-white"
                  style={{
                    height: sliderCardHeight,
                  }}
                >
                  <div className="col">
                    <div className="d-flex justify-content-center">
                      <IoStarSharp size="25px" color="rgb(200 178 116)" />
                      <IoStarSharp size="25px" color="rgb(200 178 116)" />
                      <IoStarSharp size="25px" color="rgb(200 178 116)" />
                      <IoStarSharp size="25px" color="rgb(200 178 116)" />
                      <IoStarSharp size="25px" color="rgb(200 178 116)" />
                    </div>
                    <ImQuotesLeft
                      size="15px"
                      color={isHERO ? HEROBlack : successColor}
                      className=""
                    />
                    <CommentContainer className="text-primary">
                      <TextCompactor
                        fullText={review.comment}
                        textLengthToShow={150}
                      />
                    </CommentContainer>
                    <ImQuotesRight
                      size="15px"
                      color={isHERO ? HEROBlack : successColor}
                      className="float-end"
                    />
                  </div>

                  <div className="text-primary fw-bold">- {review.name}</div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <Separator />
    </div>
  )
}

const Separator = styled.hr`
  border: 5px solid transparent;
  padding: 2% 0px 2% 0px;
  margin: 0px;
  opacity: 0;
`

const CommentContainer = styled.div`
  height: 100px;
  overflow: auto;
  @media screen and (max-width: 576px) {
    height: 125px;
  }
`

const Arrow = styled.div`
  position: absolute;
  display: block;
  z-index: 10;
  cursor: pointer;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
`

const NextArrow = props => {
  const [iconWidth, setIconWidth] = useState("50px")
  const [positionRight, setPositionRight] = useState("-50px")
  useEffect(() => {
    if (window.innerWidth >= 576) {
      setIconWidth("50px")
      setPositionRight("-50px")
    } else {
      setIconWidth("25px")
      setPositionRight("-20px")
    }
  }, [])
  return (
    <Arrow
      onKeyDown={props.onClick}
      onClick={props.onClick}
      role="button"
      tabIndex={0}
      style={{
        right: positionRight,
      }}
    >
      <IoIosArrowForward
        size={iconWidth}
        color={props.handleColor ? HEROPrimary : successColor}
      />
    </Arrow>
  )
}

const PrevArrow = props => {
  const [iconWidth, setIconWidth] = useState("50px")
  const [positionLeft, setPositionLeft] = useState("-50px")
  useEffect(() => {
    if (window.innerWidth >= 576) {
      setIconWidth("50px")
      setPositionLeft("-50px")
    } else {
      setIconWidth("25px")
      setPositionLeft("-20px")
    }
  }, [])
  return (
    <Arrow
      onKeyDown={props.onClick}
      onClick={props.onClick}
      role="button"
      tabIndex={0}
      style={{
        left: positionLeft,
      }}
    >
      <IoIosArrowBack
        size={iconWidth}
        color={props.handleColor ? HEROPrimary : successColor}
      />
    </Arrow>
  )
}

export default ReviewContainer
