import React, { useState, useEffect } from "react"
import Layout from "../components/common/layout"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import HeroSection from "../components/hero-section"
import ContactFooter from "../components/contact-footer"
import PartnerSlider from "../components/partner-slider"
import KnownFrom from "../components/home/knownFrom"
import ReviewContainer from "../components/review-container"
import { FaArrowRight } from "@react-icons/all-files/Fa/FaArrowRight"

import CardSection from "../components/card-section"
import VideoReviews from "../components/video-reviews"
import VideoWidthCaption from "../components/video-with-caption"
import Video from "../components/common/video"
import CircleProgress from "../components/circle-progress"
import BierdeckelPieChart from "../images/svg/Bierdeckel_drei_zahlen_Pie_chart.svg"
import BierdeckelPieChartMobile from "../images/svg/Bierdeckel_drei_zahlen_Pie_chart_for_Web_mobile.svg"
import {
  Videocontainer,
  BGLinearGradient,
  ButtonPrimary,
  Separator,
  SeparatorColorFull,
} from "../components/common/styledComponentsHelper"
import {
  workerProblemsText,
  ourSolutionText,
  seminar,
  ourPromises,
  warrantyText,
} from "../static/text/homepageContent"
import { secondaryColor, successColor } from "../static/styles/colors"
import { videoCaptionText, customerReview } from "../static/text/commonContent"
import AppointletButton from "../components/common/appointletButton"
import { Link } from "gatsby"

export const Head = () => (
  <>
    <title>Mit 3 Zahlen zu mehr Gewinn | Fachhandwerk360</title>
    <meta name="description" content="Alles was ein Handwerker braucht" />
    <meta
      name="keywords"
      content="Kalkulation, Beratung, Digitalisierung, Handwerk, Software, kaufmännischer
      Begleiter Bauhandwerk"
    />
    <meta
      name="facebook-domain-verification"
      content="xooo742lkxtkqa24ish1wyf2ppfc4e"
    />
  </>
)

const IndexPage = () => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const scriptTag = document.createElement("script")
    scriptTag.src =
      "https://www.provenexpert.com/widget/landing_fachhandwerk360-dreier-herber-gbr.js?feedback=0&avatar=0&competence=0&style=black"
    scriptTag.addEventListener("load", () => setLoaded(true))
    document.body.appendChild(scriptTag)
  }, [])

  useEffect(() => {
    if (!loaded) return
  }, [loaded])

  return (
    <Layout>
      <HeroSection
        desktopImg="homepage_banner_desktop.png"
        mobileImg="homepage_banner_mobile.png"
        headlineText="Bierdeckelkalkulation"
        responsiveHeadline="Bierdeckel kalkulation"
        subheadlineText="Mit 3 Zahlen zu mehr Gewinn!"
        captionText="Kaufmännische Begleitung für das Bauhandwerk"
        buttons={true}
      />
      <BGLinearGradient>
        <KnownFrom />
        <Separator />
        <div className="container text-light pb-md-5 pt-md-5">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <h2 className="text-uppercase text-center fw-bold">
                Ihr Handwerks<span className="d-sm-none d-inline">-</span>
                betrieb kann <span className="text-success">Mehr</span>
              </h2>
              <div className="text-center mt-4 fs-5">{workerProblemsText}</div>
            </div>
          </div>
        </div>
        <SeparatorColorFull />
        <div className="container text-light pb-md-5 pt-md-5">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <h2 className="text-uppercase text-center fw-bold">
                Wir sind die <span className="text-success">Lösung</span>
              </h2>
              <div className="text-center mt-4 fs-5">{ourSolutionText}</div>
            </div>
          </div>
        </div>
        <Separator />
        <BGLinearGradientVideo>
          <div className="container p-sm-5 p-4">
            <h2 className="text-center fw-bold text-light pt-4 text-uppercase">
              Das sagen unsere <span className="text-success">Kunden</span>
            </h2>
            <VideoWidthCaption
              headlineText={
                <>
                  Endlich mehr <span className="text-success">Gewinn!</span>
                </>
              }
              videoURL="https://www.youtube-nocookie.com/embed/KzZwXMKM3zo?rel=0"
              videoCaption="Die Bierdeckelkalkulation - Das sagen unsere Handwerkskunden!"
              captionText={customerReview}
            />
            <VideoReviews />
          </div>
        </BGLinearGradientVideo>
        <CircleProgress />
        <SeparatorColorFull />
        <div className="bg-warning bg-opacity-25">
          <ReviewContainer />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-12">
                <div id="pewl" style={{ maxWidth: "100%" }} />
              </div>
            </div>
          </div>
          <Separator />
        </div>
        <Separator />
        <div className="container p-0">
          <h2 className="text-uppercase text-center text-light fw-bold">
            Wir tauchen für Sie tiefer in die{" "}
            <span className="text-success">Zahlen ein</span>
          </h2>
          <div className="w-75 mx-auto">
            <BierdeckelPieChart className="d-md-block d-none d-block" />
          </div>
          <BierdeckelPieChartMobile className="d-md-none d-block w-100" />
        </div>
        <div className="bg-dark">
          <Separator />
          <h2 className="text-light text-center text-uppercase fw-bold">
            <span className="text-success">Bierdeckelkalkulation</span>
          </h2>
          <CardSection />
          <Separator />
        </div>
        <Separator />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-12">
              <h2 className="text-uppercase text-light text-center fw-bold">
                Noch nicht überzeugt? Besuchen Sie uns doch in einem unserer{" "}
                <span className="text-success">Live Online Seminare</span>
              </h2>
              <div className="text-center text-light mt-4 fs-5">{seminar}</div>
              <div className="col-lg-12 text-center p-4">
                <Link to="/online-seminar/organic">
                  <ButtonPrimary>
                    Jetzt kostenlos Anmelden
                    <FaArrowRight className="ms-3" color={successColor} />
                  </ButtonPrimary>
                </Link>
              </div>
            </div>
          </div>
          <SeparatorColorFull />
          <div className="row">
            <div className="col-xl-6 ps-sm-5 ps-3 pe-sm-5 pe-3">
              <table className="h-100 w-100">
                <tbody>
                  <tr>
                    <td className="pt-3 pb-3 align-middle text-end">
                      <h2 className="text-uppercase text-light fw-bold">
                        Wie komme ich zu mehr{" "}
                        <span className="text-success">Gewinn?</span>
                      </h2>
                      <div className="text-light mt-4 fs-5">
                        {videoCaptionText}
                      </div>
                      <br />
                      <AppointletButton />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-xl-6 ps-sm-5 ps-3 pe-sm-5 pe-3">
              <Videocontainer>
                <Video
                  videoSrcURL="https://www.youtube-nocookie.com/embed/zzSkQNJyqPQ?rel=0"
                  videoTitle="Die Bierdeckelkalkulation - So funktioniert sie!"
                  videoStyle={{
                    top: "0",
                    left: "0",
                    height: "100%",
                  }}
                />
              </Videocontainer>
            </div>
          </div>
          <div className="row flex-lg-row-reverse">
            <Separator />
            <div className="col-xl-6 ps-sm-5 ps-3 pe-sm-5 pe-3">
              <table className="h-100">
                <tbody>
                  <tr>
                    <td className="pt-3 pb-3 align-middle text-start">
                      <h2 className="text-uppercase fw-bold text-light">
                        Unser Versprechen an{" "}
                        <span className="text-success">Sie</span>
                      </h2>
                      <div className="text-light">{ourPromises}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-xl-6 ps-sm-5 ps-3 pe-sm-5 pe-3">
              <StaticImage
                src="../images/homepage/unser_versprechen.png"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Unser Versprechen an Sie"
                placeholder="none"
              />
            </div>
          </div>
          <div className="row">
            <Separator />
            <div className="col-xl-6 ps-sm-5 ps-3 pe-sm-5 pe-3">
              <table className="h-100 ms-auto">
                <tbody>
                  <tr>
                    <td className="pt-3 pb-3 align-middle text-end">
                      <Underline className="text-uppercase fw-bold text-light ms-auto">
                        100 % zufriedenheitsgarantie
                      </Underline>
                      <div className="text-light">{warrantyText}</div>
                      <AppointletButton />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-xl-6 ps-sm-5 ps-3 pe-sm-5 pe-3">
              <StaticImage
                src="../images/homepage/zufriedenheitsgarantie.png"
                layout="fullWidth"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Unser Versprechen an Sie"
                placeholder="none"
                className="w-50 mx-auto my-auto"
              />
            </div>
          </div>
        </div>
        <Separator />
        <ContactFooter />
        <PartnerSlider />
      </BGLinearGradient>
    </Layout>
  )
}

const Underline = styled.h2`
  text-decoration: underline;
  text-decoration-color: ${successColor};
  text-underline-offset: 5px;
  line-height: 1.6 !important;
  @media screen and (max-width: 768px) {
    font-size: 1.75rem !important;
    text-underline-offset: 7px;
    line-height: 1.4 !important;
  }
  @media screen and (max-width: 450px) {
    font-size: 1.5rem !important;
  }
  @media only screen and (max-width: 400px) and (min-width: 300px) {
    font-size: 1.25rem !important;
  }import ReviewContainer from './../components/home/review-container';
import loadPE from './../components/common/loadProvenExpert';
`

const BGLinearGradientVideo = styled.div`
  background: linear-gradient(
    90deg,
    transparent 0%,
    ${secondaryColor} 10%,
    ${secondaryColor} 90%,
    transparent 100%
  );
`

export default IndexPage
