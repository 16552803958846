import React from "react"

export const workerProblemsText = (
  <React.Fragment>
    <p>Sie verstehen Ihr Handwerk – und wir unseres!</p>
    <p>
      Sie möchten das volle Potenzial aus Ihrem Handwerksbetrieb rausholen?
      <br />
      Sie möchten wissen, ob alle Ihre Aufträge rentabel sind? <br />
      Sie möchten wissen, wo Sie kaufmännisch stehen und in die Tiefe Ihrer
      Zahlen abtauchen?
      <br />
      Ihnen fehlt die Zeit und der Ansatz alles zu analysieren? <br />
      Dann starten Sie jetzt mit der Bierdeckelkalkulation!
    </p>
  </React.Fragment>
)

export const ourSolutionText = (
  <React.Fragment>
    Wir begleiten Sie mit der Bierdeckelkalkulation durch das ganze Jahr.
    <br />
    Mit der Bierdeckelkalkulation bringen wir Sie auf Ihren gewünschten
    Gewinnkurs –
    <br />
    Wichtig für Sie, Ihre Familie und Ihre Mitarbeiter.
    <br />
    Mit der Bierdeckelkalkulation nehmen Sie Ihren Betrieb erfolgreich in die
    Hand und haben jederzeit alles im Überblick.
    <p>
      Vergangenheit ist Vergangenheit: Starten Sie noch heute in die Zukunft und
      haben stets die passende Vorwärtsstrategie für Ihren Handwerksbetrieb!
    </p>
    <p>
      Verlieren Sie keine Zeit: Nehmen Sie kostenlos und unverbindlich Kontakt
      zu uns auf!
    </p>
  </React.Fragment>
)

export const seminar = (
  <React.Fragment>
    <p className="fs-5">
      Mit nur drei Zahlen zum sicheren Gewinn bei jedem Auftrag!
    </p>
    <br />
    <p className="fs-5">
      Handwerker sind Praktiker – und doch verlassen Sie sich bei der
      Kalkulation oft genug auf ihr Bauchgefühl, rechnen nach der Methode „Pi
      mal Daumen“. Die Folge: Über 80 % der Handwerker verschenken viel Geld
      durch zu geringe Stundensätze, falsche Materialaufschläge, hohen
      Zeitaufwand und Personaleinsatz.
    </p>
    <p className="fs-5">
      Das muss nicht sein. Das zeigt Ihnen dieser Vortrag: Eine einfache
      Kalkulation mit nur drei Zahlen, die Platz auf einem Bierdeckel hat,
      garantiert Ihren Gewinn bei jedem Auftrag.
    </p>
  </React.Fragment>
)

export const ourPromises = (
  <ul>
    <li className="list-style-check">
      <span className="fs-5">
        Wir sind Ihr kaufmännischer Begleiter auf Augenhöhe & geben unser
        Jahrzehnte langes Wissen an Sie weiter
      </span>
    </li>
    <li className="list-style-check">
      <span className="fs-5">
        Wir sind erst dann zufrieden, wenn Sie es sind
      </span>
    </li>
    <li className="list-style-check">
      <span className="fs-5">
        Wir stehlen Ihnen keine Zeit-Wir bringen Ihnen Zeit
      </span>
    </li>
    <li className="list-style-check">
      <span className="fs-5">Mit uns haben Sie Ihre Zahlen im Griff</span>
    </li>
  </ul>
)

export const warrantyText = (
  <React.Fragment>
    <p className="fs-5">
      Sind Sie{" "}
      <span className="text-decoration-underline">nicht zufrieden</span>, dann
      erhalten Sie Ihr{" "}
      <span className="text-decoration-underline"> Geld zurück!</span>
      <br />
      Mittelmäßigkeit ist für uns ein Fremdwort.
    </p>
    <br />
    <p className="fs-SegoeScript h3">Rainer Dreier &amp; Martin Herber</p>
    <p className="fs-5">Wir beraten Sie gerne</p>
  </React.Fragment>
)
