import React from "react"
import Slider from "react-slick"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import "slick-carousel/slick/slick.css"

const KnownFrom = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(
          filter: { relativeDirectory: { eq: "hero_banner/known_from" } }
        ) {
          edges {
            node {
              name
              id
              childImageSharp {
                gatsbyImageData(
                  width: 200
                  quality: 95
                  formats: [AUTO, WEBP, AVIF]
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    `}
    render={data => {
      const settings = {
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 4000,
        cssEase: "linear",
        arrows: false,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      }
      const renderImages = data.allFile.edges.map(image => (
        <div className="w-100" key={image.node.id}>
          <div style={{ width: "max-content" }} className="d-block mx-auto">
            <GatsbyImage
              image={getImage(image.node.childImageSharp.gatsbyImageData)}
              alt={"logo " + image.node.name}
            />
          </div>
        </div>
      ))
      return (
        <div className="container-fluid pt-5 pb-5">
          <h2 className="text-uppercase text-center fw-bold text-light">
            Bekannt <span className="text-success">aus:</span>
          </h2>
          <AlignCenter>
            <Slider {...settings}>{renderImages}</Slider>
          </AlignCenter>
        </div>
      )
    }}
  />
)

const AlignCenter = styled.div`
  & > div > div > div {
    display: flex;
    align-items: center;
  }
`

export default KnownFrom
