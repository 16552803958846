import React, { useState } from "react"
import styled from "styled-components"

const TextCompactor = ({ fullText, textLengthToShow }) => {
  const [showMore, setShowMore] = useState(false)
  const [buttonLabel, setButtonLabel] = useState("Mehr anzeigen")
  const [restOfText, setRestOfText] = useState()
  let shortText = fullText.substr(0, textLengthToShow - 1)
  shortText = shortText.substr(
    0,
    Math.min(shortText.length, shortText.lastIndexOf(" "))
  )

  const showRestOfText = text => {
    if (!showMore) {
      setRestOfText(text.substr(shortText.length, text.length - 1))
      setButtonLabel("Weniger anzeigen")
      setShowMore(true)
    } else {
      setRestOfText()
      setButtonLabel("Mehr anzeigen")
      setShowMore(false)
    }
  }

  if (fullText.length > textLengthToShow) {
    return (
      <>
        {showMore ? shortText : shortText + "..."}
        {restOfText}
        <Button
          className="btn btn-link p-0 text-start d-block text-primary"
          onClick={() => showRestOfText(fullText)}
        >
          {buttonLabel}
        </Button>
      </>
    )
  } else {
    return fullText
  }
}

export default TextCompactor

const Button = styled.button`
  box-shadow: none !important;
`
